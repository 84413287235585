import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import AppBarLight from "./AppBarLight";
import TextField from "@mui/material/TextField";
import darkTable from "../img/dark-table.jpg";
import logo from "../img/divine-logo-transparent.png";
import emailjs from "emailjs-com";
import { setDoc, doc, getFirestore } from "firebase/firestore";

const Contact = () => {
	const db = getFirestore();
	const form = useRef();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [message, setMessage] = useState("");

	const handleFirstName = (e) => {
		console.log(e.target.value);
		setFirstName(e.target.value);
	};
	const handleLastName = (e) => {
		console.log(e.target.value);
		setLastName(e.target.value);
	};
	const handleEmail = (e) => {
		console.log(e.target.value);
		setEmail(e.target.value);
	};
	const handlePhoneNumber = (e) => {
		console.log(e.target.value);
		setPhoneNumber(e.target.value);
	};
	const handleMessage = (e) => {
		console.log(e.target.value);
		setMessage(e.target.value);
	};

	const sendMessage = async (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				"service_24b6fpa",
				"template_d2or1lx",
				form.current,
				"UmH33kvemMfVc331o"
			)
			.then(
				(result) => {
					console.log(result.text);
				},
				(error) => {
					console.log(error.text);
				}
			);

		try {
			const createStamp = new Date();
			const datetime =
				createStamp.getMonth() +
				1 +
				"-" +
				createStamp.getDate() +
				"-" +
				createStamp.getFullYear() +
				" " +
				createStamp.getHours() +
				":" +
				createStamp.getMinutes() +
				":" +
				createStamp.getSeconds() +
				":" +
				createStamp.getMilliseconds();
			const docRef = await setDoc(doc(db, "messages", datetime), {
				firstName: firstName,
				lastName: lastName,
				email: email,
				phoneNumber: phoneNumber,
				message: message,
				created: datetime,
			});
		} catch (e) {
			console.error("Error adding document: ", e);
		}

		setFirstName("");
		setLastName("");
		setEmail("");
		setPhoneNumber("");
		setMessage("");
	};
	return (
		<Grid container className="scrollContainer">
			<Grid item xs={12}>
				<section>
					<Grid container>
						<Grid item xs={12} className="contact-app-bar-section">
							<AppBarLight />
						</Grid>
						<Grid item xs={12} md={6}>
							<section className="contact-section-one mx-10 my-10">
								<p className="text-5xl font-bold">
									Let's Work Together
								</p>
								<br></br>
								<p className="">
									Let’s chat about your special day. We’ll
									create something unforgettable.
								</p>
							</section>
						</Grid>
						<Grid item xs={12} md={6}>
							<section className="mx-10 my-10">
								<form ref={form} onSubmit={sendMessage}>
									<Grid container>
										<Grid item xs={12} md={6}>
											<div className="mb-10">
												<TextField
													id="standard-basic"
													label="First Name"
													variant="standard"
													value={firstName}
													onChange={handleFirstName}
													name="first_name"
												/>
											</div>
										</Grid>

										<Grid item xs={12} md={6}>
											<div className="mb-10">
												<TextField
													id="standard-basic"
													label="Last Name"
													variant="standard"
													value={lastName}
													onChange={handleLastName}
													name="last_name"
												/>
											</div>
										</Grid>

										<Grid item xs={12}>
											<div className="mb-10">
												<TextField
													id="standard-basic"
													label="Email"
													variant="standard"
													fullWidth
													value={email}
													onChange={handleEmail}
													name="email"
												/>
											</div>
										</Grid>

										<Grid item xs={12}>
											<div className="mb-10">
												<TextField
													id="standard-basic"
													label="Phone"
													variant="standard"
													fullWidth
													value={phoneNumber}
													onChange={handlePhoneNumber}
													name="phone_number"
												/>
											</div>
										</Grid>

										<Grid item xs={12}>
											<div className="mt-5">
												<TextField
													id="outlined-multiline-static"
													label="Message"
													multiline
													rows={4}
													fullWidth
													value={message}
													onChange={handleMessage}
													name="message"
												/>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div className="mt-10">
												<Button
													size="large"
													variant="contained"
													disableElevation
													sx={{
														backgroundColor:
															"rgb(127 29 29)",
														color: "white",
														width: "125px",
														height: "75px",
														textTransform:
															"capitalize",
														border: "none",
														borderRadius: 0,
														fontSize: "20px",
														"&:hover": {
															transitionDuration:
																"700ms",
															backgroundColor:
																"black",
														},
													}}
													type="submit"
												>
													Send
												</Button>
											</div>
										</Grid>
									</Grid>
								</form>
							</section>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section
					className="home-section-five"
					style={{
						backgroundImage: `url(${darkTable})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 62%",
						backgroundRepeat: "no-repeat",
					}}
				></section>
			</Grid>
			<Grid item xs={12} className="mt-5 mb-5">
				<section className="home-section-four flex justify-center align-center items-center text-center bg-white mt-10 mb-10 ">
					<Grid
						container
						className="flex justify-center items-center text-center"
					>
						<Grid item xs={12} sm={4}>
							<p className="text-black flex justify-center items-center text-center font-bold underline underline-offset-4">
								Email
							</p>
							<p className="text-black font-bold">
								divinehospitality21@gmail.com
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							sm={4}
							className="flex justify-center items-center text-center"
						>
							<img
								src={logo}
								width="70%"
								className="flex justify-center items-center text-center"
							/>
						</Grid>
						<Grid item xs={12} sm={4}>
							<p className="text-black flex justify-center items-center text-center font-bold underline underline-offset-4">
								Call
							</p>
							<p className="text-black font-bold">
								(863) 258 7178
							</p>
						</Grid>
					</Grid>
				</section>
			</Grid>
		</Grid>
	);
};

export default Contact;
