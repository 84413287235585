import React from "react";
import AppBar from "./AppBar";
import "@fontsource/poppins/300.css";
import { Grid } from "@mui/material";
import redFoodImg from "../img/red-food.jpg";
import grapesTableImg from "../img/grapes-table.jpg";
import candlesTableImg from "../img/candles-table.jpg";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import dHTable from "../img/dh-table.jpeg";
import darkTable from "../img/dark-table.jpg";
import logo from "../img/divine-logo-transparent.png";
import lightTable from "../img/light-table.jpg";

const Layout = () => {
	return (
		<Grid container className="scrollContainer">
			<Grid item xs={12}>
				<section
					style={{
						backgroundImage: `url(${candlesTableImg})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 65%",
						backgroundRepeat: "no-repeat",
					}}
					className="home-section-one flex justify-center "
				>
					<Grid container>
						<Grid item xs={12}>
							<AppBar></AppBar>
						</Grid>

						<Grid
							item
							xs={12}
							className="items-center text-center justify-center flex"
						>
							<p className="text-white font-extrabold tracking-wider text-xl">
								Hospitality
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							className="flex justify-center text-center"
						>
							<p className=" text-white font-bold text-6xl mt-2">
								Brought To You
							</p>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section className="home-section-two flex justify-center items-center text-center">
					<Grid container>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={0} md={3}></Grid>
								<Grid item xs={12} md={6}>
									<p className=" font-bold text-4xl">
										Exceptional service, brought to your
										home or venue
									</p>
									<br></br>
									<br></br>
								</Grid>
								<Grid item xs={0} md={3}></Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={0} md={2}></Grid>

								<Grid item xs={12} md={8}>
									<p className="">
										Over a decade of experience in all
										aspects of the hospitality industry.
										Every event we staff has a dedicated
										lead ensuring you have everything taken
										care of, before and during your special
										day. We bring the exceptional service
										you expect from your favorite dining
										experiences, to your home or venue.
									</p>
									<br></br>
									<p className="text-red-900">
										Dine with Divine.
									</p>
								</Grid>

								<Grid item xs={0} md={2}></Grid>
							</Grid>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section
					className="home-section-five"
					style={{
						backgroundImage: `url(${dHTable})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 70%",
						backgroundRepeat: "no-repeat",
					}}
				></section>
			</Grid>
			<Grid item xs={12}>
				<section className="home-section-three bg-white flex justify-center">
					<Grid container className="flex items-center pl-5">
						<Grid item xs={12} md={11}>
							<p className="text-red-900 text-5xl font-bold">
								Let's Work Together
							</p>
							<br></br>
							<p className="text-red-900">
								Let’s chat about your special day. We’ll create
								something unforgettable.
							</p>
							<br></br>
							<Link underline="none" href="/contact">
								<Button
									size="large"
									variant="contained"
									disableElevation
									sx={{
										backgroundColor: "rgb(127 29 29)",
										color: "white",
										width: "200px",
										height: "75px",
										textTransform: "capitalize",
										border: "none",
										borderRadius: 0,
										fontWeight: "bold",
										fontSize: "20px",
										"&:hover": {
											transitionDuration: "700ms",
											backgroundColor: "black",
										},
									}}
								>
									Contact Us
								</Button>
							</Link>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section
					className="home-section-five"
					style={{
						backgroundImage: `url(${darkTable})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 62%",
						backgroundRepeat: "no-repeat",
					}}
				></section>
			</Grid>
			<Grid item xs={12} className="mt-5 mb-5">
				<section className="home-section-four flex justify-center align-center items-center text-center bg-white mt-5 mb-5">
					<Grid
						container
						className="flex justify-center items-center text-center"
					>
						<Grid item xs={12} md={4}>
							<p className="text-black flex justify-center items-center text-center font-bold underline underline-offset-4">
								Email
							</p>
							<p className="text-black font-bold">
								divinehospitality21@gmail.com
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className="flex justify-center items-center text-center"
						>
							<img
								src={logo}
								width="70%"
								className="flex justify-center items-center text-center"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<p className="text-black flex justify-center items-center text-center font-bold underline underline-offset-4">
								Call
							</p>
							<p className="text-black font-bold">
								(863) 258 7178
							</p>
						</Grid>
					</Grid>
				</section>
			</Grid>
		</Grid>
	);
};

export default Layout;
