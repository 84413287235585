import React from "react";
import { Grid, Link, Button } from "@mui/material";
import candlesTableImg from "../img/candles-table.jpg";
import AppBar from "./AppBar";
import logo from "../img/divine-logo-transparent.png";
import darkTable from "../img/dark-table.jpg";
import dHTable from "../img/dh-table.jpeg";

const About = () => {
	return (
		<Grid container className="scrollContainer">
			<Grid item xs={12}>
				<section
					style={{
						backgroundImage: `url(${candlesTableImg})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 65%",
						backgroundRepeat: "no-repeat",
					}}
					className="home-section-one flex justify-center "
				>
					<Grid container>
						<Grid item xs={12}>
							<AppBar></AppBar>
						</Grid>
						<Grid
							item
							xs={12}
							className="flex justify-center text-center"
						>
							<p className=" text-white font-bold text-6xl">
								What We Do
							</p>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section className="about-mission-section flex justify-center items-center text-center">
					<Grid container>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={0} md={3}></Grid>
								<Grid item xs={12} md={6}>
									<p className=" font-bold text-4xl">
										Exceptional service, brought to your
										home or venue
									</p>
									<br></br>
									<br></br>
								</Grid>
								<Grid item xs={0} md={3}></Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={0} md={2}></Grid>

								<Grid item xs={12} md={8}>
									<p className="">
										We provide high quality servers or
										bartenders for your event. We come with
										over a decade of experience in all
										aspects of the hospitality industry.
										Every event we staff has a dedicated
										lead ensuring you have everything taken
										care of, before and during your special
										day. We bring the exceptional service
										you expect from your favorite dining
										experiences, to your home or venue.
									</p>
									<br></br>
									<p className="text-red-900">
										Dine with Divine.
									</p>
								</Grid>

								<Grid item xs={0} md={2}></Grid>
							</Grid>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section
					className="about-services-section flex justify-center"
					style={{
						backgroundImage: `url(${darkTable})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 62%",
						backgroundRepeat: "no-repeat",
					}}
				>
					<Grid container className="flex ml-5 mt-5 text-white">
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={12}>
									<p className="text-5xl font-bold tracking-wide">
										Services
									</p>
									<Grid item xs={12}>
										<Grid container>
											<Grid item xs={12} md={3}></Grid>
											<Grid item xs={12} md={3}>
												<ul>
													<li className="text-3xl mt-3 ml-5">
														Servers
														<ul>
															<li className="list-disc text-lg mt-2">
																Professionally
																Trained
															</li>
															<li className="list-disc text-lg">
																Bilingual
																Available
															</li>
															<li className="list-disc text-lg">
																5+ years
																experience
															</li>
														</ul>
													</li>
												</ul>
											</Grid>
											<Grid item xs={12} md={3}>
												<ul>
													<li className="text-3xl mt-3 ml-5">
														Bartenders
														<li className="list-disc text-lg mt-2">
															Hand Crafted
															Cocktails
														</li>
														<li className="list-disc text-lg">
															Mixology Tools
														</li>
														<li className="list-disc text-lg">
															5+ years experience
														</li>
													</li>
												</ul>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>

						<Grid
							item
							xs={12}
							className="text-center font-bold text-xl underline underline-offset-4"
						>
							<p>Licensed and Insured</p>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12} className="mb-5">
				<section className="about-events-section flex">
					<Grid container className="flex ml-5 text-red-900">
						<Grid item xs={12} md={2}>
							<p className="text-5xl font-bold tracking-wide mt-5">
								Events
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							md={10}
							className="flex items-center "
						>
							<ul className="ml-5">
								<li className="list-disc text-lg">Weddings</li>
								<li className="list-disc text-lg">Mitzvahs</li>
								<li className="list-disc text-lg">
									Birthday Parties
								</li>
								<li className="list-disc text-lg">
									Engagements
								</li>
								<li className="list-disc text-lg">
									Corporate Events
								</li>
								<li className="list-disc text-lg">
									Fundraisers
								</li>
							</ul>
						</Grid>
					</Grid>
				</section>
			</Grid>

			<Grid item xs={12} className="mb-5">
				<section className="about-price-section bg-black flex">
					<Grid container className="flex ml-5 text-white">
						<Grid item xs={12} md={1}>
							<p className="text-5xl font-bold tracking-wide mt-5">
								Prices
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							md={11}
							className="items-center flex"
						>
							<ul className="ml-5">
								<li className="list-disc text-lg">
									Four hour minimum for Bartender or Server
								</li>
								<li className="list-disc text-lg">
									Servers starting at $35/hr
								</li>
								<li className="list-disc text-lg">
									Bartenders starting at $45/hr
								</li>
								<li className="list-disc text-lg">
									Special pricing for large events with long
									hours. Contact for a quote.
								</li>
							</ul>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section className="about-quote-section flex justify-center items-center text-center">
					<Grid container>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={0} md={3}></Grid>
								<Grid item xs={12} md={6}>
									<p className=" font-bold text-4xl">
										"The best way to find yourself is to
										lose yourself in the service of others."
									</p>
									<br></br>
									<br></br>
								</Grid>
								<Grid item xs={0} md={3}></Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid container>
								<Grid item xs={0} md={2}></Grid>

								<Grid item xs={12} md={8}>
									<p className="text-red-900">
										- Mahatma Ghandi
									</p>
								</Grid>

								<Grid item xs={0} md={2}></Grid>
							</Grid>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section className="about-contact-section bg-white flex justify-center">
					<Grid container className="flex items-center pl-5">
						<Grid item xs={12} md={11}>
							<p className="text-red-900 text-5xl font-bold">
								Let's Work Together
							</p>
							<br></br>
							<p className="text-red-900">
								Let’s chat about your special day. We’ll create
								something unforgettable.
							</p>
							<br></br>
							<Link underline="none" href="/contact">
								<Button
									size="large"
									variant="contained"
									disableElevation
									sx={{
										backgroundColor: "rgb(127 29 29)",
										color: "white",
										width: "200px",
										height: "75px",
										textTransform: "capitalize",
										border: "none",
										borderRadius: 0,
										fontWeight: "bold",
										fontSize: "20px",
										"&:hover": {
											transitionDuration: "700ms",
											backgroundColor: "black",
										},
									}}
								>
									Contact Us
								</Button>
							</Link>
						</Grid>
					</Grid>
				</section>
			</Grid>
			<Grid item xs={12}>
				<section
					className="home-section-five"
					style={{
						backgroundImage: `url(${dHTable})`,
						backgroundSize: "100% auto",
						backgroundPosition: "65% 70%",
						backgroundRepeat: "no-repeat",
					}}
				></section>
			</Grid>
			<Grid item xs={12} className="mt-5 mb-5">
				<section className="home-section-four flex justify-center align-center items-center text-center bg-white mt-5 mb-5 ">
					<Grid
						container
						className="flex justify-center items-center text-center"
					>
						<Grid item xs={12} md={4}>
							<p className="text-black flex justify-center items-center text-center font-bold underline underline-offset-4">
								Email
							</p>
							<p className="text-black font-bold">
								divinehospitality21@gmail.com
							</p>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							className="flex justify-center items-center text-center"
						>
							<img
								src={logo}
								width="70%"
								className="flex justify-center items-center text-center"
							/>
						</Grid>
						<Grid item xs={12} md={4}>
							<p className="text-black flex justify-center items-center text-center font-bold underline underline-offset-4">
								Call
							</p>
							<p className="text-black font-bold">
								(863) 258 7178
							</p>
						</Grid>
					</Grid>
				</section>
			</Grid>
		</Grid>
	);
};
export default About;
