import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import { FirebaseAppProvider } from "reactfire";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyDDxMiwv5iwmgR5NzBm8idoCd7JK9Lb2xk",
	authDomain: "divine-hospitality.firebaseapp.com",
	projectId: "divine-hospitality",
	storageBucket: "divine-hospitality.appspot.com",
	messagingSenderId: "700332791661",
	appId: "1:700332791661:web:6305b8fdfc2ead3a32ac64",
	measurementId: "G-6CV59GT554",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById("root")
);
