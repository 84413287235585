import * as React from "react";
import MUIAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "@mui/material";

const pages = ["About", "Contact"];

const AppBar = () => {
	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<MUIAppBar position="static" color="transparent">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Link underline="none" href="/">
						<Typography
							variant="h5"
							component="div"
							sx={{
								flexGrow: 1,
								color: "black",
								fontFamily: "Poppins",
								fontWeight: "bold",
								letterSpacing: "0.05em",
							}}
						>
							Divine Hospitality
						</Typography>
					</Link>

					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
						}}
					>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={handleOpenNavMenu}
							color="inherit"
							edge="end"
							sx={{ color: "black" }}
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							{pages.map((page) => (
								<MenuItem
									key={page}
									onClick={handleCloseNavMenu}
								>
									<Link
										underline="none"
										href={"/" + page}
										sx={{ color: "black" }}
									>
										<Typography textAlign="center">
											{page}
										</Typography>
									</Link>
								</MenuItem>
							))}
						</Menu>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "none", md: "flex" },
							justifyContent: "end",
						}}
					>
						{pages.map((page) => (
							<Link underline="none" href={"/" + page}>
								<Button
									key={page}
									onClick={handleCloseNavMenu}
									sx={{
										my: 2,
										color: "black",
										display: "block",
										"&:hover": {
											transitionDuration: "700ms",
											backgroundColor: "rgb(153 27 27)",
											color: "white",
										},
									}}
								>
									{page}
								</Button>
							</Link>
						))}
					</Box>
				</Toolbar>
			</Container>
		</MUIAppBar>
	);
};
export default AppBar;
