import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import { Helmet } from "react-helmet";
import Contact from "./components/Contact";
import About from "./components/About";

const App = () => {
	return (
		<BrowserRouter>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Divine Hospitality</title>
				<head>Divine Hospitality</head>

				<link rel="canonical" href="http://divinehospitality.co/" />
			</Helmet>
			<Routes>
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/" element={<Layout />} />
			</Routes>
		</BrowserRouter>
	);
};

export default App;
